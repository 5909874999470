import {
  PageOptions,
  User,
  axiosInstance,
  baseUrl,
  exportExcel,
} from '@mgk-eld/utils';
import qs from 'qs';

const endPoint = `${baseUrl.https}users`;

export interface GetUserParams extends Partial<PageOptions> {
  roles: string[];
  show_deleted?: boolean;
  email?: string;
  name?: string;
  company_id?: number;
  use_paginate?: boolean;
}

const getUsers = (params?: GetUserParams) => {
  return axiosInstance.get(`${endPoint}`, {
    params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });
};

const getUserAboutMe = () =>
  axiosInstance.get(`${baseUrl.https}users/about/me`);

const getUserById = (id: number | string) =>
  axiosInstance.get(`${endPoint}/${id}`);

const editUserById = (user: Partial<User>, id: number | string) =>
  axiosInstance.put(`${endPoint}/${id}`, user);

const createUser = (user: unknown) => axiosInstance.post(`${endPoint}`, user);

const deleteUser = (id: number, deleted = true) =>
  axiosInstance.patch(`${endPoint}/${id}`, { deleted });

const downloadXLSX = async (params: {
  company_id: string | number;
  roles: string[];
}) => {
  exportExcel(
    `${endPoint}/download/list`,
    params,
    'users.xlsx',
    axiosInstance.defaults.headers.common
  );
};

const usersApi = {
  getUsers,
  getUserById,
  editUserById,
  createUser,
  deleteUser,
  getUserAboutMe,
  downloadXLSX,
};

export default usersApi;
