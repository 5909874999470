const blue = {
  100: '#E6EFF7',
  150: '#3C87CD',
  250: '#7DBCF6',
  500: '#085DAD',
  700: '#075095',
};

const white = '#fff';
const grey = {
  50: '#F8F8F8',
  100: '#E5EAED',
  300: '#A6A9B2',
  400: '#878A91',
  600: '#434242',
  800: '#2B2A29',
  1000: '#0A0B0C',
};
const actionPrimary = '#0077FA';
const actionYellow = '#FED519';
const actionOrange = '#FFB341';
const actionRed = '#E63D3D';

const red = '#E63D3D';
const green = '#05AB83';
const yellow = '#F9CC00';
const orange = '#FB9B00';
const purple = '#6257BA';

export {
  blue,
  white,
  grey,
  red,
  green,
  yellow,
  orange,
  purple,
  actionPrimary,
  actionYellow,
  actionOrange,
  actionRed,
};
