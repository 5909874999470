import { string } from 'yup';

export const englishStringRegEx = /^[A-Za-z0-9\s!@#$%^.,%?&*()_+-]+$/;

export const englishString = string().matches(
  englishStringRegEx,
  'English letters only'
);

export const passwordString = string()
  .min(8, 'Password should be 8 characters long')
  .matches(/[A-Z]/, 'Password should contain at least 1 capital letter')
  .matches(/[0-9]/, 'Password should contain at least 1 digit')
  .matches(/[a-z]/, 'Password should contain at least 1 lowercase letter')
  .matches(/[^A-Za-z0-9]/, 'Password should contain at least 1 special symbol');

export const macAddressRegex = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;

export const isRequired = ' is a required field';

export const urlRegex =
  /^(https?:\/\/)?([\wа-яА-ЯёЁ.+-]+)\.([a-zа-яА-ЯёЁ]{2,})([/\wа-яА-ЯёЁ.+-]*)*(\?[;&\wа-яА-ЯёЁ+=%_.-]*)?(#[\wа-яА-ЯёЁ+-]*)?$/;

export const noCommaRegex = /^[^,]*$/;
export const locationRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
